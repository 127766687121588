#menuAdmin {
  font-family: $headings-font-family;
  li {
    margin: 0;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  .menuAdminSearch {
    order: 5;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;

    button {
      position: absolute;
      right: 0;
    }
  }

  #siteSearch {
    max-width: $header-search-input-max-width;
  }

  .socialIcons {
    order: 6;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.pageHeader .headerContent .mainCallToAction {
  margin-left: auto;
}

.mainCallToAction .cta-button.e-newsletter:not([class*="donate"]) {
  background-color: $azure-blue;
  color: $navy-blue;
  &:hover {
    background-color: lighten($azure-blue, 5%);
  }
}

.homepage-quickgiving--container {
  flex-direction: column;
}

.homepage-quickgiving--copy {
  margin: 0 0 1rem;
}

// Footer

.Footer-Legal {
  text-align: center;
}

// Header mobile fixes
@media (max-width: map-get($breakpoints, sm)) {
  #menuAdmin .menuAdminSearch {
    display: none;
  }
  #menuAdmin .socialIcons {
    display: none;
  }
}

.homefeaturecategory-homeboximpactstats:before {
  background-attachment: fixed;
}

.quickGivingPanel {
  font-family: 'Roboto', sans-serif;
}

.homefeaturecategory-homeboxquickgiving {
  background-image: url($assets-path + "lighter-blue-nr.png");
  background-size: cover;
  h2 {
    font-size: 2.5rem;
    margin: 0 auto 1rem;
    text-align: center;
    width: 100%;
  }
}

.Footer-Logos {
  grid-column: 1 / span 16;
  text-align: center;
  background: white;
  margin: 40px 0 0;
}

.pageFooter {
  padding-bottom: 0;
  font-family: 'Roboto', serif;
}

.footerBox[class*="ewsletter"] button:hover {
  background-color: lighten($azure-blue, 5%);
}

.homeFeature h2 a,
.carouselSlideHeading a,
.feedItem .feedTitle {
  text-decoration: initial;
  border-bottom: 3px solid transparent;
  border-bottom-color: transparent;
  transition: all .3s ease-in-out;
  }
.homeFeature:hover h2 a,
.carousel:hover .carouselSlideHeading a,
.feedItem:hover .feedTitle a {
  border-bottom-color: $azure-blue;
}

@media (min-width: map-get($breakpoints, sm)) {
  .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding-top: 0;
  }
}

.cookie-preference-container a {
  font-family: 'Roboto', sans-serif;
}

blockquote p:first-child:before, 
blockquote p:last-child:after {
    font-family: 'FontAwesome';
    display: inline-block;
    font-weight: normal;
}

blockquote p:first-child:before { 
    content: '\f10d';
    margin-right: 8px;
}

blockquote p:last-child:after {
    content: '\f10e';
    margin-left: 8px;
}

.breadcrumbItem {
  font-family: 'Roboto', sans-serif;
}

.menuMain .header-search .searchContainer input {
  font-family: 'Roboto', sans-serif;
}

.pageFooterWrapper {
  background-image: url($assets-path + "lighter-blue-nr.png");
  background-size: cover;
}