$platform-name: "peterlemarchanttrust";

$navy-blue: #01385E;
$azure-blue: #29ABE2;
$azure-blue-accessible: #177fab;
$postbox-red: #ED1C24;
$postbox-red-accessible: #eb131a;
$postbox-red: $postbox-red-accessible;
$harbour-grey: #D4D5D5;
$fog-grey: #F5F6F1;
$brand-primary: $navy-blue;
$brand-secondary: $azure-blue;
$donate-colour: $postbox-red;

$grey-dark: #1e1e1e;
$grey-lightest: #f0f0f0;

$spacer: 1rem;

$link-colour: $postbox-red;

$container-max-width: 1170px;
$gap-width: 40px;

$border-radius: 0;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


$logo: "PTLM-logo.png";
$logo-retina: $logo;
$logo-width: 400px;
$logo-height: 64px;

$font-family-base: 'Roboto Serif', serif;
$font-size-base: 1rem;
$body-colour: #1e1e1e;
$headings-font-family:  'Roboto', sans-serif;
$headings-colour: $navy-blue;
$headings-text-transform: none;
$headings-font-weight: 700;
$headings-letter-spacing: initial;

$btn-font-family: $headings-font-family;
$btn-text-transform: uppercase;
$btn-font-weight: 700;
$btn-font-size: 1em;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-border-radius: 4px;


$menu-admin-background-colour: $brand-primary;
$menu-admin-link-colour: text-contrast($brand-primary);
$menu-admin-font-size: 0.75rem;
$menu-admin-align-items: left;

$header-content-margin-top: $spacer * 2;
$header-content-margin-bottom: $spacer * 2;

$tagline-enabled: false;

$header-search-enabled: true;
$header-search-input-max-width: 200px;
$header-search-margin-right: 0;
$header-search-input-margin-right: 0;
$header-search-input-border-radius: 0;
$header-search-input-border-width: 0;
$header-search-input-background-colour: rgba(#fff, 0.2);
$header-search-input-placeholder-colour: text-contrast($menu-admin-background-colour);
$header-search-button-icon-colour: text-contrast($menu-admin-background-colour);

$header-search-button-border-radius: 0;
$header-search-button-background-colour: transparent;

$cta-btn-gap: .5rem;
$cta-btn-breakpoint: map-get($breakpoints, lg);

$nav-background-colour: $fog-grey;
$nav-text-transform: none;
$nav-font-family: $headings-font-family;
$nav-normal-max-width: 100%;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-margin-bottom: 20px;
$nav-top-level-item-padding: 1rem 1.5rem;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-hover-background-colour: $harbour-grey;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-font-weight: bold;
$nav-submenu-background-colour: $fog-grey;
$nav-submenu-item-padding: 10px 1.5rem;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-hover-background-colour: $harbour-grey;
$nav-submenu-item-font-weight: bold;
$nav-submenu-item-hover-colour: $brand-primary;
$nav-search-button-icon-colour: white;
//$nav-search-button-background-colour: $azure-blue;
$nav-search-input-margin-right: -40px;

$page-title-colour: $headings-colour;

$carousel-details-background-colour: rgba(#fff, 0.95);
$carousel-details-padding: 2rem;
$carousel-details-max-width: 40%;
$carousel-details-margin-y: 2rem;
$carousel-details-margin-x: 2rem;
$carousel-details-border-radius: 4px;
$carousel-heading-colour: $navy-blue;
$carousel-heading-font-size: 1.5em;
$carousel-summary-enabled: true;
$carousel-summary-colour: $body-colour;
$carousel-read-more-enabled: false;
$carousel-controls-enabled: false;
$carousel-controls-button-border-radius: 25px;
$carousel-controls-button-gap: 5px;
$carousel-controls-margin-y: 10px;
$carousel-controls-margin-x: 10px;
$carousel-controls-button-background-colour: white;
$carousel-dots-position-x: right; // left, center, right
$carousel-dots-padding: $spacer * 2;
$carousel-dots-active-colour: white;

$home-intro-font-family: $headings-font-family;
$home-intro-font-size: 2rem;
$home-intro-line-height: 1.45em;
$home-intro-font-weight: 700;
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-margin-top: 4rem;
$home-intro-padding-y: 0;
$home-intro-colour: $navy-blue;

$shop-enabled: true;
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-item-heading-colour: $navy-blue;

$blog-details-enabled: true;
$blog-details-profile-pic-enabled: false;

$home-features-padding-top: calc(4rem - (#{$gap-width} / 2));
$home-features-padding-bottom: $home-features-padding-top;

$impact-stats-background-image: "paddleboard.jpg";
$impact-stats-background-image-opacity: 0.25;
$impact-stats-max-width: 100%;
$impact-stats-background-colour: $brand-primary;
$impact-stats-padding-y: 4rem;
$impact-stats-margin-bottom: 4rem;
$impact-stats-heading-font-size: 2.5rem;
$impact-stats-heading-margin-bottom: 1rem;
$impact-stats-figure-colour: white;
$impact-stats-figure-font-size: 5rem;
$impact-stats-summary-font-size: 1rem;

$quick-giving-donation-amount-figure-font-size: 1.375rem;
$quick-giving-donation-amount-background-colour: #fff;
$quick-giving-donation-amount-selected-background-colour: $postbox-red;
$quick-giving-background-colour: $navy-blue;
$quick-giving-border-radius: 4px;
$home-quick-giving-max-width: 1130px;
$home-quick-giving-padding-y: 4rem;
$home-quick-giving-margin-bottom: 4rem;
$home-quick-giving-heading-font-size: 2.5rem;
$home-quick-giving-donation-amount-background-colour: #fff;
$home-quick-giving-donation-amount-selected-background-colour: $quick-giving-donation-amount-selected-background-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-heading-colour: white;

$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 4rem;

$feeds-title-font-size: 2rem;
$feeds-title-colour: $navy-blue;

$card-border: 0;
$card-image-border-radius: $border-radius;
$card-details-padding: 1.5rem;
$card-heading-colour: $navy-blue;
$card-hover-heading-colour: $navy-blue;
$card-heading-font-size: 1.5em;
$card-footer-background-colour: transparent;
$card-footer-padding: 0 $card-details-padding;
$card-hover-image-scale: 1.1;
$card-image-background-colour: $azure-blue;
$card-hover-image-opacity: 0.5;


$card-side-by-side-image-width: 40%;
$card-side-by-side-grow-image: false;

$footer-boxes-count: 5;
$footer-prefab: 4;
$footer-padding-y: 3rem;
$footer-background-colour: $navy-blue;
$footer-link-colour: white;
$footer-font-size: 0.875rem;
$footer-headings-font-size: 1.375rem;

$newsletter-background-colour: transparent;
$newsletter-heading-font-size: $footer-headings-font-size;
$newsletter-heading-text-align: left;
$newsletter-button-colour: $navy-blue;
$newsletter-colour: white;

$sidebar-adjacent-width: 260px;
$sidebar-adjacent-gap: 120px;

$shop-enabled: false;